<template>
  <div class="container_con">
    <el-card>
      <!-- <div class="search">
          <div>
              <el-input icon="el-icon-search" v-model="queryParams.batch_number" @keyup.enter.native="handleQuery" placeholder="请输入批号" clearable
                  style="width:300px;margin-right:10px"></el-input>
              <el-button type="primary" @click="handleQuery()">搜索</el-button>
          </div>
      </div> -->
      <div style="margin-top:20px">
        <el-table :data="list"  v-loading="loading" style="width: 100%">
          <el-table-column prop="stock" label="数量" />
          <el-table-column prop="batch_number" label="批号" />
          <el-table-column label="有效期至">
            <template v-slot="scope">
              {{scope.row.valid_until || '长效期'}}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="个别计价" />
          <el-table-column fixed="right" label="操作" width="150">
            <template v-slot="scope">
              <el-button type="text" size="small" @click="handleUpdate(scope.row)" v-if="have_auth('/Batchnum/edit')">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
</template>

<script>
export default {
  name: "packaging",
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          batch_number: ''
      },
      total:0,
      list:[]
    };
  },
  created() {
    this.queryParams.batch_number=this.$route.query.id || '';
    this.getList();
  },
  methods: {
    
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/BatEdit?id='+row.id)
    },
    /** 查询列表 */
    getList() {
        this.loading = true;
        this.$httpGet("/backend/Warehouse/getBatchNumber", this.queryParams).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    }
  },
};
</script>

<style lang="scss" scoped></style>